import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideToastr } from 'ngx-toastr';
import { interceptorInterceptor } from './shared/interceptador/interceptor.interceptor';
import { provideNgxMask } from 'ngx-mask';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptors([interceptorInterceptor]), withFetch()),
    provideRouter(routes), 
    provideClientHydration(),
    provideAnimations(),
    provideToastr(
      {
          timeOut: 5000,
          positionClass: 'toast-bottom-right',
          preventDuplicates: true,
          progressBar: true,
      }
    ),
    provideNgxMask(),
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ]
};
