import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guardas/auth.guard';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    { 
        path: '**', 
        redirectTo: '' 
    },

];
