import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthService } from './shared/services/auth/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor(
    private authS: AuthService,
    private router: Router
  ) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (e.url === '/') {
          this.router.navigate(['/dashboard'])
        }
      }
    })
  }

  ngOnInit() {
    this.authS.renovarToken()
  }
}
