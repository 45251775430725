import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';

export const interceptorInterceptor: HttpInterceptorFn = (req, next) => {

  const _toastr = inject(ToastrService);

  return next(req).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 400) {
           if(err.error?.errors.length > 0) {
             _toastr.error(err.error.errors[0], "Algo deu errado!")
           }
        }else if (err.status === 401) {
          _toastr.error("Sem permissão para essa ação", "Acesso negado")
        }else if (err.status === 404) {
          _toastr.error("Nenhum resultado encontrado.", "Nenhum Resultado")
        }else if (err.status === 500) {
          _toastr.error(err.message, "Erro Interno")
        }
      } else {
        console.error('Ocorreu um erro:', err);
      }
      return throwError(() => err); 
    })
  )
};
